<script setup lang="ts">
import type { Game } from "@/types";

const emit = defineEmits<{ (event: "toggleFavorite", game: Game): void; (event: "close"): void }>();

const props = defineProps<{
	game: Game & { skeleton?: boolean };
	type?: "slider_vertical" | "slider_horizontal";
}>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { t } = useT();
const loginGuard = useLoginGuard();
const { open, close } = useNlcModals();
const { handleOpenGame } = useOpenGame(open);

const defaultBg = "/nuxt-img/card-games/default-bg.png";
const isGameFavorite = computed(() => props.game.isFavorite);

const handleToggleToFavorite = () => {
	emit("toggleFavorite", { ...props.game, isFavorite: isGameFavorite.value });
};

const handleOpenGameClick = () => {
	loginGuard({
		success() {
			if (!props.game.gamePlayInfo) {
				close("LazyOModalRaceGames");
			}
			emit("close");
			handleOpenGame(props.game.slug);
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div>
		<MGameCardHorizontal
			v-if="type === 'slider_horizontal'"
			:game="game"
			:gameImgHorizontal="`${baseImageUrl}${props.game?.logo2x1 || props.game?.logo}`"
			:gameImg="`${baseImageUrl}${props.game?.logo}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="284"
			:height="174"
			:buttonName="t('Play')"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCardHorizontal>
		<MGameCard
			v-else-if="type === 'slider_vertical'"
			:game="game"
			:gameImg="`${baseImageUrl}${props.game?.logo1x2 || props.game?.logo}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="{ full: 158, lg: 120 }"
			:height="{ full: 277, lg: 210 }"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
		<MGameCard
			v-else
			:game="game"
			:gameImg="`${baseImageUrl}${props.game?.logo || props.game?.img}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="{ full: 158, lg: 120 }"
			:height="{ full: 178, lg: 140 }"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
	</div>
</template>

<style scoped lang="scss">
.game-card-horizontal {
	background: var(--neutral-15);
	border-radius: 12px;
}
:deep(.game-card) {
	.image-block {
		border-radius: 12px;
		background: var(--neutral-15);
	}
	.game-overlay {
		background-color: rgba(var(--neutral-5-rgb), 0.85);
		.nuxt-icon {
			font-size: 44px;
		}
	}
	.btn-favorite {
		bottom: 4px;
		right: 4px;
		padding: 2px;
		border-radius: 6px;
		background: var(--neutral-5);
		width: 24px;
		height: 26px;
	}
}
</style>
